import React, { useState } from "react";
import axios from "axios";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { BASE_URL } from '../../../constant/index';
import { useAuth } from "../../../context/AuthProvider";

const AddTradeStrategy = ({
    openAddStrategyModal,
    setAddStrategyModalOpen,
    selectedTradeId,
    setSuccessMsg,
    setErrorMsg,
    setLoading,
    getRecommendations,
}) => {
    const { token } = useAuth();
    const [input, setInput] = useState({
        tradeType: '',
        stockSymbol: '',
        futuresLotSize: '',
        recommendedDate: '',
        lastPrice: 0,
        currentPrice: 0,
        openingPrice: 0,
        closingPrice: '',
        currentGainLossPer: '',
        tradeStrategies: '',
    });

    React.useEffect(() => {
        if (selectedTradeId) {
            setInput({
                tradeType: selectedTradeId.TRADE_TYPE || '',
                stockSymbol: selectedTradeId.STOCK_SYMBOL || '',
                futuresLotSize: selectedTradeId.FUTURES_LOT_SIZE || '',
                recommendedDate: selectedTradeId.RECOMMENDED_DATE || '',
                lastPrice: selectedTradeId.LAST_PRICE || 0,
                currentPrice: selectedTradeId.CURRENT_PRICE || 0,
                openingPrice: selectedTradeId.OPENING_PRICE || 0,
                closingPrice: selectedTradeId.CLOSING_PRICE || '',
                currentGainLossPer: selectedTradeId.GAIN_LOSS_PER || '',
                tradeStrategies: '',
            });
        }
    }, [selectedTradeId]);

    const onCurrentPrice = (event) => {
        setInput(prevState => ({
            ...prevState,
            currentPrice: event.target.value,
        }));  
    }

    const onOpeningPriceChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            openingPrice: event.target.value,
        }))
    }

    const onClosingPriceChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            closingPrice: event.target.value,
        }))
    }

    const onTradeStrategiesChange = (event) => {
        setInput(prevState => ({
            ...prevState,
            tradeStrategies: event.target.value,
        }));
    }

    const calculateChange = () => {
        if(input.closingPrice) {
            let percentageChange = 0;
            if(input.tradeType === 'Buy') {
                percentageChange = (Number(input.closingPrice) - Number(input.openingPrice)) * input.futuresLotSize;
            } else {
                percentageChange = (Number(input.openingPrice) - Number(input.closingPrice)) * input.futuresLotSize;
            }
            
            setInput(prevState => ({
                ...prevState,
                currentGainLossPer: percentageChange.toFixed(2),
            }));  
        } else if(input.currentPrice) {
            let percentageChange = 0;
            if(input.tradeType === 'Buy') {
                percentageChange = (Number(input.currentPrice) - Number(input.openingPrice)) * input.futuresLotSize;
            } else {
                percentageChange = (Number(input.openingPrice) - Number(input.currentPrice)) * input.futuresLotSize;
            }
            
            setInput(prevState => ({
                ...prevState,
                currentGainLossPer: percentageChange.toFixed(2),
            }));  
        } else {
            setInput(prevState => ({
                ...prevState,
                currentGainLossPer: 'N/A',
            }));  
        }
    }

    const handleCloseAddStrategyModal = () => setAddStrategyModalOpen(false);

    const handleAddNotes = async () => {
        if(input.futuresLotSize > 0) {
            setSuccessMsg('');
            setErrorMsg('');
            try {
                setLoading(true);
                const { data } = await axios.post(BASE_URL+'/updateTradeStrategy', { input: {
                    ...input,
                    id: selectedTradeId.TRADE_ID,
                }}, {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });
                if(data?.msg) {
                    setSuccessMsg(data.msg);
                    getRecommendations();
                }
            } catch(err) {
                setErrorMsg('Server encountered an issue, please try later. If issue still persist, please reach out on help@capitaltrends.in');
            } finally {
                setAddStrategyModalOpen(false);
                setLoading(false);
            }
        }
    }

    const handleTradeType = (event) => {
        setInput(prevState => ({
            ...prevState,
            tradeType: event.target.value,
        }))
    }
    
    const onFuturesLotSize = (event) => {
        setInput(prevState => ({
            ...prevState,
            futuresLotSize: event.target.value,
        }));
    }

    return (
        <Dialog
            open={openAddStrategyModal}
            onClose={handleCloseAddStrategyModal}
            maxWidth='sm'
            fullWidth={true}
        >
            <DialogContent>
                <DialogContentText component={'div'}>
                    <Stack spacing={2}>
                        <Typography component={'span'} noWrap>Updating Strategy for {selectedTradeId?.STOCK_SYMBOL}</Typography>
                        
                        <Stack spacing={4}>
                            <FormControl fullWidth>
                                <InputLabel id="tradeType">Trade Type</InputLabel>
                                <Select
                                    labelId="tradeType"
                                    id="tradeType"
                                    value={input.tradeType}
                                    label="Trade Type"
                                    onChange={handleTradeType}
                                >
                                    <MenuItem value=''>Please select</MenuItem>
                                    <MenuItem value='Buy'>Buy</MenuItem>
                                    <MenuItem value='Sell'>Sell</MenuItem>
                                </Select>
                            </FormControl>  
                        </Stack>

                        <Stack direction="row" spacing={3}>
                            <TextField 
                                id="currentPrice"
                                label="Current Price" 
                                variant="standard" 
                                onChange={onCurrentPrice}
                                onBlur={calculateChange}
                                value={input.currentPrice}
                                sx={{ width: '250px' }}
                            />

                            <TextField 
                                id="openingPrice"
                                label="Opening Price" 
                                variant="standard" 
                                onChange={onOpeningPriceChange}
                                value={input.openingPrice}
                                sx={{ width: '250px' }}
                            />
                        </Stack>

                         <Stack direction="row" spacing={3}>
                            <TextField 
                                id="closingPrice"
                                label="Closing Price" 
                                variant="standard"
                                onChange={onClosingPriceChange}
                                onBlur={calculateChange}
                                value={input.closingPrice}
                                sx={{ width: '250px' }}
                            />
                            <TextField 
                                id="futuresLotSize"
                                label="Futures Lot Size" 
                                variant="standard" 
                                value={input.futuresLotSize}
                                sx={{ width: '250px' }}
                                helperText="*Lot Size cannot be zero"
                                onChange={onFuturesLotSize}
                            />
                        </Stack>    

                        <TextField 
                            variant="standard"
                            id="currentGainLoss" 
                            label="Current Gain/Loss" 
                            value={input.currentGainLossPer}
                            disabled
                        />

                        <TextField 
                            id="addTradeStrategy"
                            label="Trading Notes"
                            onChange={onTradeStrategiesChange}
                            variant="standard" 
                        />
                    </Stack>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAddNotes}>Submit</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddTradeStrategy;