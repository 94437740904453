import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom';
import AuthProvider from "../context/AuthProvider";
import Header from './Header/Header';
import Home from './Content/Home/Home';
import SignUp from './Content/SignUp/SignUp';
import SignIn from './Content/SignIn/SignIn';
import AboutUs from './Content/AboutUs/AboutUs';
import ForgotPassword from './Content/ForgotPassword/ForgotPassword';
import CreatePassword from './Content/ForgotPassword/CreatePassword';
import CoachingHome from './Content/CoachingHome/CoachingHome';
import ContactUs from './Content/ContactUs/ContactUs';
import { MemoizedFooter } from './Footer/Footer';
import PrivateRoute from './PrivateRoute';
import ChangePassword from './Content/ChangePassword/ChangePassword';
import UpdateProfile from './Content/UpdateProfile/UpdateProfile';
import './styles.css';

const Layout = ({ children }) => {
    const location = useLocation();
    const showHeaderFooter = !['/sign-in', '/sign-up'].includes(location.pathname) && location.pathname.split('/')[1] !== 'activateUser';
    return (
        <>
            {showHeaderFooter && <Header />}
            {children}
            {showHeaderFooter && <MemoizedFooter />}
        </>
    );
};

const App = () => (
    <Router>
        <AuthProvider>
            <Routes>
                <Route
                    path="*"
                    element={
                        <Layout>
                            <Routes>
                                <Route exact path="/" element={<Home />} />
                                <Route exact path="/home" element={<Home />} />
                                <Route path="/activateUser/:uniqueId" element={<SignIn />} />
                                <Route path="/changePassword/:uniqueId" element={<CreatePassword />} />
                                <Route exact path="/sign-up" element={<SignUp />} />
                                <Route exact path="/sign-in" element={<SignIn />} />
                                <Route exact path="/about-us" element={<AboutUs />} />
                                <Route exact path="/forgot-password" element={<ForgotPassword />} />
                                <Route exact path="/change-password" element={<ChangePassword />} />
                                <Route exact path="/update-profile" element={<UpdateProfile />} />
                                <Route exact path="/1-1-Coaching" element={<CoachingHome />} />
                                <Route exact path="/contact-us" element={<ContactUs />} />
                                <Route element={<PrivateRoute />}>
                                    <Route exact path="/marketDashboard" element={<Home />} />
                                    <Route exact path="/patternRecognition" element={<Home />} />
                                    <Route exact path="/futuresTrades" element={<Home/>} />
                                </Route>
                            </Routes>
                        </Layout>
                    }
                />
            </Routes>
        </AuthProvider>
    </Router>
);

export default App;
