import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TradeRecommendations from "../../TradeRecommendation/TradeRecommendations";
import MarketDashboard from "../../MarketDashboard/MarketDashboard";
import PatternRecognition from "../../PatternRecognition/PatternRecognition";
import { useAuth } from "../../../../context/AuthProvider";
import { ADMIN_USERS } from '../../../../constant/index';

const a11yProps = (index) => ({
  id: `services-tab-${index}`,
  'aria-controls': `services-tabpanel-${index}`,
});

const Content = () => {
  const { userEmail } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState('marketDashboard');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  React.useEffect(() => {
    const path = location.pathname.substring(1);
    setValue(path);

    if (path === 'home') {
      navigate('/marketDashboard');
    } else {
      setValue(path);
    }
  }, []);

  return (
    <div className="container py-5">
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
            value={value}
            onChange={handleChange}
            aria-label="home page content"
            TabIndicatorProps={{
              style: {
                backgroundColor: "black",
              },
            }}
          >
            {[
              <Tab
                key="marketDashboard"
                label="Market Dashboard"
                to="/marketDashboard"
                value="marketDashboard"
                component={Link}
                {...a11yProps(1)}
              />,
              <Tab
                key="patternRecognition"
                label="Pattern Recognition"
                to="/patternRecognition"
                value="patternRecognition"
                component={Link}
                {...a11yProps(2)}
              />,
              ...(ADMIN_USERS.includes(userEmail)
                ? [
                    <Tab
                      key="futuresTrades"
                      label="Futures Trades"
                      to="/futuresTrades"
                      value="futuresTrades"
                      component={Link}
                      {...a11yProps(3)}
                    />,
                  ]
                : []),
            ]}
          </Tabs>
        </Box>
        <Box sx={{ padding: 2 }}>
          {value === 'futuresTrades' && <TradeRecommendations />}
          {value === 'marketDashboard' && <MarketDashboard />}
          {value === 'patternRecognition' && <PatternRecognition />}
        </Box>
      </Box>
    </div>
  );
}

export default Content;